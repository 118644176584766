<template>
	<!-- 分销商等级规则 -->
	<div class="dis-rule el-content">
		<a-button v-has="{action:'wxapp_marketing_dis_rule_add'}" type="primary" @click="showAdd(0)">
			<i class="ri-add-line"></i>
			添加规则 
		</a-button>
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'规则名称',dataIndex:'title'},
			{title:'类型',dataIndex:'condition',slots:{customRender:'condition'}},
			{title:'排序',dataIndex:'rank'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #condition="{record}">
				<a-tag color="#00CC66">{{conditionType(record.condition)}}</a-tag>
			</template>
			<template #action="{record}">
				<a-space>
					<kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'wxapp_marketing_dis_rule_add'}"  @click="showAdd(record)"></kd-button>
					<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_marketing_dis_rule_del'}"  @click="deleteLevel(record.id)"></kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getDisRule(info.page,e)}"
				@change="(e)=>{getDisRule(e,info.limit)}"
			/>
		</div>
		<a-modal v-model:visible="show.add" title="升级规则" @ok="saveAddForm" width="600px" @cancel="show.add = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="规则名称">
					<a-input v-model:value="addForm.title"></a-input>
				</a-form-item>
				<a-form-item label="升级条件">
					<a-radio-group v-model:value="addForm.condition">
						<a-radio :value="1">团队业绩总额</a-radio>
						<a-radio :value="2">下线分销商数量</a-radio>
						<a-radio :value="3">下线某一个分销商等级数量人数</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="团队业绩总额" v-if="addForm.condition==1">
					<a-input v-model:value="addForm.condition_num" addon-after="元可升级" addon-before="团队业绩总额达到"></a-input>
				</a-form-item>
				<a-form-item label="下线分销商数量" v-if="addForm.condition==2">
					<a-input v-model:value="addForm.condition_num" addon-after="人可升级" addon-before="下线分销商数量"></a-input>
				</a-form-item>
				<a-form-item label="等级id" v-if="addForm.condition==3">
					<a-select v-model:value="addForm.condition_num">
						<a-select-option v-for="(item , index) in rule" :key="index" :value="item.id">{{item.name}}</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="等级id达到人数" v-if="addForm.condition==3">
					<a-input v-model:value="addForm.lv_num"></a-input>
				</a-form-item>
				<a-form-item label="排序" >
					<a-input v-model:value="addForm.rank"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import distributionModel from '@/api/addons/distribution'
import commonModel from '@/api/common'
export default{
	computed:{
		conditionType(){
			return function(e){
				return e == 1 ?'团队业绩总额' :(e==2?'下线分销商数量' :'下线某一个分销商等级数量人数')
			}
		}
	},
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			show:{
				add:false,
			},
			addForm:{
				title:'',
				condition:0,
				condition_num:'',
				lv_num:'',
				rank:99,
			},
			rule:[],
		})
		getDisRule(1,state.info.limit)
		distributionModel.getDisLevel(res=>state.rule = res)

		function getDisRule(page,limit){
			distributionModel.getDisRule(page,limit,res=>state.info = {limit,...res})
		}

		function showAdd(row){
			state.addForm = {
				id:row ? row.id :0,
				rank:row ? row.rank :99,
				title:row ? row.title :"",
				lv_num:row ? row.lv_num :"",
				condition:row ? row.condition :0,
				condition_num:row ? row.condition_num :"",
			}
			state.show.add = true
		}

		const saveAddForm = ()=>distributionModel.addOrEditDisRule(state.addForm,()=>{
			getDisRule(state.info.page,state.info.limit)
			state.show.add = false
		})

		const deleteLevel =(id)=>commonModel.deleteDataList(id,45,"确认删除规则吗？").then(()=>{
			getDisRule(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getDisRule,
			saveAddForm,
			deleteLevel,
			showAdd
		}
	}
}
</script>

<style>
</style>
